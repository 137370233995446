import React from 'react'

function Footer() {
  return (
    <footer style={{padding: "15px 0"}}>
        <center>VS &copy; {new Date().getFullYear()}</center>
    </footer>
  )
}

export default Footer
